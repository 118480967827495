<script setup lang="ts">
import type { PageBodyElementTypes } from '~~/types/api/page/PageBodyElement'

interface Props {
  type: PageBodyElementTypes
  content: any
  reverse?: boolean
  id: string
}

const props = withDefaults(defineProps<Props>(), { reverse: false })

const useResolvePostBlockComponent = () => {
  let PostBlockComponent: ReturnType<typeof resolveComponent>
  const postBlockProps: { id: string, content: any, reverse?: boolean } = { id: props.id, content: props.content }

  switch (props.type) {
    case 'Gallery': {
      PostBlockComponent = resolveComponent('LazyAppPostBlockGallery')
      break
    }
    case 'Iframe': {
      PostBlockComponent = resolveComponent('LazyAppPostBlockIframe')
      break
    }
    case 'OrderedList': {
      PostBlockComponent = resolveComponent('LazyAppPostBlockOrderedList')
      break
    }
    case 'RichText': {
      PostBlockComponent = resolveComponent('LazyAppPostBlockRichText')
      break
    }
    case 'RichTextHighlighted': {
      PostBlockComponent = resolveComponent('LazyAppPostBlockRichTextHighlighted')
      break
    }
    case 'Separator': {
      PostBlockComponent = resolveComponent('LazyAppPostBlockSeparator')
      break
    }
    case 'UnorderedList': {
      PostBlockComponent = resolveComponent('LazyAppPostBlockUnorderedList')
      break
    }
    case 'CategorySection': {
      PostBlockComponent = resolveComponent('LazyAppPostBlockCategorySection')
      postBlockProps.reverse = props.reverse
      break
    }
    case 'TagSection': {
      PostBlockComponent = resolveComponent('LazyAppPostBlockTagSection')
      postBlockProps.reverse = props.reverse
      break
    }
    case 'VehiclesListing': {
      PostBlockComponent = resolveComponent('LazyAppPostBlockVehiclesListing')
      postBlockProps.reverse = props.reverse
      break
    }
    case 'VehiclesSearch': {
      PostBlockComponent = resolveComponent('LazyAppPostBlockVehiclesSearch')
      break
    }
    case 'Hyperlink': {
      PostBlockComponent = resolveComponent('LazyAppPostBlockHyperlink')
      break
    }
    default: {
      console.warn(`unknown component "${props.type}"`)
      PostBlockComponent = resolveComponent('LazyAppPostBlockDefault')
    }
  }

  return { PostBlockComponent, postBlockProps }
}
const { PostBlockComponent, postBlockProps } = useResolvePostBlockComponent()
</script>

<template>
  <PostBlockComponent v-bind="postBlockProps" />
</template>
